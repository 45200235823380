<template>
  <div class=a-tabs>
    <div class=headers>
      <slot name=headers>
        <a class=tab v-for='(name,i) of names' :key=name @click.prevent.stop='set(i)' :class='{active:tab===i}'>{{name}}</a>
      </slot>
    </div>
    <slot :tab=tab />
  </div>
</template>
<script>
import {computed,ref} from 'vue'
import {watchEffect} from 'vue'
export default {
  props: {
    headers: [String,Array],
    modelValue: Number,
  },
  setup(props, {emit}) {
    const names = computed(() => props.headers?.split?.(',') ?? props.headers ?? [])
    const tab = ref(0)
    watchEffect(() => {
      if (typeof props.modelValue === 'number') {
        tab.value = props.modelValue
      }
    })
    const set = i => {
      if (typeof props.modelValue === 'number') {
        emit('update:modelValue', i)
      }
      else tab.value = i
    }

    return {
      names,
      tab,
      set,
    }
  }
}
</script>
<style lang="stylus">
.a-tabs {
  & > .headers {
    $bbt = 1px // borda de todos
    $bba = 3px // borda do ativo
    flex 0 0 auto
    & + * {
      flex 1 1 0
    }

    border-bottom $bbt solid $bg-gray
    padding-bottom $pb = 5px
    margin-bottom 34px
    & > .tab {
      color inherit
      text-decoration none
      cursor pointer
      padding ($pb + 1px + $bbt/2 - $bba/2) 6px
      margin-left 30px
      &.active {
        font-weight bold
        border-bottom $bba solid $base
      }
      &:first-child {
        margin-left 0
      }
    }
  }
}
</style>
