<template>
  <div class=a-more :class="{active,'has-border':hasBorder}">
    <div class=handler @click.stop=toggle :class={empty:isHandlerEmpty}>
      <slot name=handler>
        <span /><span /><span />
      </slot>
    </div>
    <div class=contents v-if=active @click.stop>
      <slot />
    </div>
  </div>
</template>
<script>
import {ref} from 'vue'
import {watch} from 'vue'
import {computed} from 'vue'
export default {
  props: {
    hasBorder: Boolean,
    close: Boolean, // fecha o conteúdo sempre que isso MUDA pra true
  },
  setup(props, {slots}) {
    const active = ref(false)

    const remove = () => {
      active.value = false
      document.body.removeEventListener('click', remove)
    }
    const toggle = () => {
      if (!active.value) {
        active.value = true
        document.body.addEventListener('click', remove, {passive: true})
      }
      else remove()
    }

    watch(() => props.close, v => {
      if (v) remove()
    })

    const isHandlerEmpty = computed(() => !slots.handler)

    return {
      active,
      toggle,
      isHandlerEmpty,
    }
  }
}
</script>
<style lang="stylus">
$bg = #FFF
$shadow = 0px 0px 10px -2px rgba(0, 0, 0, 0.4)
.a-more {
  display inline-block
  vertical-align middle
  position relative
  border-radius 6px
  border-bottom-left-radius 0px
  border-bottom-right-radius 0px
  transition background-color $time ease
  & > .handler {
    cursor pointer
    padding 12px 14px

    &.empty {
      grid-gap 5px
      display inline-grid
      grid-template-columns repeat(3, 1fr)
      & > span {
        width 6px
        height @width
        border-radius 50%
        display inline-block
        border 1px solid $base
        transition background-color $time ease
      }
    }
  }
  &.active {
    box-shadow $shadow
    background-color $bg
    & > .handler.empty > span {
      background-color $base
    }
    &::after {
      content ' '
      position absolute
      border 3px solid $bg
      bottom -1px
      left 0px
      width calc(100% - 6px)
      z-index 1
    }
  }
  & > .contents {
    position absolute
    z-index 1
    top 100%
    right 0px
    min-width calc(100% + 6px)
    border-radius 6px
    border-top-right-radius 0px
    padding 6px 12px
    background-color $bg
    box-shadow $shadow
  }
}
// assume borda de 1px
.a-more.has-border {
  border 1px solid $base
  & > .contents {
    right -1px
    border 1px solid $base
  }
}
</style>
