<template>
  <button class=a-button :class={danger,warn,gray,fill} :disabled=disabled>
    <a-icon :name=icon v-if=icon /> <slot />
  </button>
</template>
<script>
export default {
  props: {
    icon: String,
    fill: Boolean,
    gray: Boolean,
    warn: Boolean,
    danger: Boolean,
    disabled: Boolean,
  },
}
</script>
<style lang="stylus">
.a-button {
  color $base
  stroke @color
  height $input-height
  cursor pointer
  padding 6px 12px
  border-radius 6px
  border 1px solid $base
  background-color white
  font-size inherit
  font-family inherit
  vertical-align middle
  outline none
  & > .a-icon {
    stroke inherit
  }
  &.fill {
    font-weight bold
    background-color $base
    color $contrast !important // important porque altera os de baixo
    stroke @color
  }
  &.gray {
    border-color $fg-gray
    color $fg-gray
    stroke @color
    &.fill {
      background-color $bg-gray
      border-color $bg-gray
    }
  }
  &.warn {
    border-color $fg-warn
    color $fg-warn
    stroke @color
    &.fill {
      background-color $bg-warn
      border-color $bg-warn
      color $contrast
    }
  }
  &.danger {
    border-color $fg-danger
    color $fg-danger
    stroke @color
    &.fill {
      background-color $bg-danger
      border-color $bg-danger
    }
  }
}
</style>
