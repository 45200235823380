<template>
  <div class=c-found-list>
    <a-input placeholder='Pesquisar' icon=search v-model=query />
    <div class=options v-if=select.active>
      <a-button @click=select.all>Selecionar tudo</a-button>
      <a-button @click=select.non>Selecionar nenhum</a-button>
      <a-button @click=select.ena>Ativar</a-button>
      <a-button @click=select.dis>Desativar</a-button>
    </div>
    <div class=list>
      <c-funcionario v-for='fun of filtered' @click="$emit('show',fun.index)"
        :key=fun.code :modelValue=fun selectable activable compact
        :class='{disabled:fun.disable}'
        v-model:selected=fun.selected

      />
    </div>
    <a-button class=send @click="$emit('confirm',active)" icon=send>
      <span>Enviar para {{active.length}} funcionários</span>
    </a-button>
  </div>
</template>
<script>
import {funcionarios,found} from '@/store'
import {Funcionario,regexFromString} from 'i/utils'
import {computed,reactive,ref} from 'vue'
export default {
  setup() {
    const query = ref('')

    const fun = (code,i) => {
      const $code = parseInt(code)
      const func = funcionarios.find(x => parseInt(x.code) === $code)
      if (func) return reactive({...func, index: i, disable: !func.active})
      return reactive(Funcionario({code, index: i, selected: false, active: false, disable: true, name: '[Desconhecido]'}))
    }
    const converted = computed(() => found.map(fun))
    const filtered = computed(() => {
      const len = converted.value.length // fazendo uso pra evitar que perca reatividade no early-return
      const q = query.value?.trim()
      if (!q || len === 0) return converted.value
      const rgx = regexFromString(q)
      return converted.value.filter(x =>
        x.code.includes(q) || rgx.test(x.name) || rgx.test(x.place)
      )
    })
    const select = reactive({
      active: computed(() => filtered.value.find(x => x.selected)),
      all() { for (const x of filtered.value) x.selected = true },
      non() { for (const x of filtered.value) x.selected = false },
      ena() { for (const x of filtered.value) if (x.selected && !x.disable) x.active = true ; select.non() },
      dis() { for (const x of filtered.value) if (x.selected && !x.disable) x.active = false; select.non() },
    })
    const active = computed(() => converted.value.filter(x => x.active))

    return {
      query,
      filtered,
      select,
      active,
    }
  }
}
</script>
<style lang="stylus">
.c-found-list {
  display flex
  flex-direction column
  & > .a-input {
    margin-bottom 28px
    flex 0 0 auto
  }
  & > .list {
    flex 1 1 0
    overflow auto
  }
  & > .options {
    flex 0 0 auto
    display flex
    justify-content center
    margin-bottom 12px
    .a-button {
      margin-right 12px
      &:last-child {
        margin-right 0
      }
    }
  }
  & > .send {
    margin-top 40px
    margin-left auto
  }
  & > .list > .disabled {
    background-color #ffe0a6
    .a-switch {
      pointer-events none
    }
  }
}
</style>
