<template>
  <div id=site-menu :class={visible}>
    <a-logo />
    <ul class=page-list>
      <router-link v-for='(p,i) of pages' :to='{name:p.name}' :key=i class=item active-class=active>
        <a-icon :name=p.icon /><span>{{p.label}}</span>
      </router-link>
    </ul>
    <div class=version>
      Versão {{version}}
    </div>
  </div>
</template>
<script>
import {useScrollActivation} from '@/imports/compose.js'
import {routes} from '@/router'
export default {
  setup() {
    const {active} = useScrollActivation()

    return {
      pages: routes,
      visible: active,
      version: process.env.VERSION,
    }
  }
}
</script>

<style lang="stylus">
#site-menu {
  top 0px
  left 0px
  position fixed

  height 100vh
  display flex
  flex-direction column

  shadow right
  background-color $base
  border-top-right-radius 42px
  border-bottom-right-radius 42px
  & > .a-logo {
    margin-top 45px
    margin-bottom 53px
  }
  & > .page-list {
    user-select none
    list-style none
    & > .item {
      outline none
      display list-item
      cursor pointer
      padding 12px 10px
      margin-bottom 24px
      border-top-left-radius 11px
      border-bottom-left-radius 11px
      background-color alpha($background, 0.4)
      transition background-color $time ease
      text-decoration none
      color black
      &:hover {
        background-color alpha($background, 0.7)
      }

      &.active {
        background-color white
        position relative
        &::after {
          content ' '
          background-color $background
          position absolute
          top 0px
          width 7px
          right - @width
          height 100%
        }
      }
    }
    .a-icon {
      margin-right 10px
    }
    span {
      vertical-align middle
    }
  }
  & > .version {
    margin-top auto
    color $contrast
    text-align center
    margin-bottom 8px
  }
}
+mobile() {
  #site-menu {
    height 60px
    bottom 0px
    top unset !important
    z-index 1
    border-radius 0px
    shadow top
    .a-logo,.version {
      display none
    }
    .page-list {
      display flex
      align-items center
      justify-content space-around
      margin-top 0px
      padding-left 0px
      & > .item {
        display flex
        align-items center
        flex-direction column
        background-color transparent !important
        & > .a-icon {
          margin 0
        }
        &.active {
          color white
          font-weight bold
          & > .a-icon {
            stroke white
            margin 0
          }
        }
        &::after {
          display none
        }
      }
    }
  }
  // lida com a animação ao fazer scroll
  #site-menu {
    transition transform $time ease-out
    transform translateY(100%) translateY(10px) // 10px pra sombra
    &.visible {
      transform translateY(0px)
    }
  }
}

// tamanhos e tal
#site-menu {
  width $menu-width = 300px
  & + #site-contents {
    margin-left $menu-width
  }

  +mobile() {
    width 100vw
    & + #site-contents {
      margin-left unset
    }
  }
}
</style>
