<template>
  <div class=a-logo :class={big}>
    <a-icon name=logo />
    <span>{{name}}</span>
  </div>
</template>
<script>
export default {
  props: {
    big: Boolean,
  },
  setup() {
    return {
      name: process.env.NAME,
    }
  }
}
</script>
<style lang="stylus">
.a-logo {
  color $contrast
  display flex
  align-items center
  flex-direction column
  cursor default
  user-select none
  .icon-logo {
    width 82px
    height @width
    stroke $contrast
    stroke-width 2px
  }
  & > span {
    margin-top 4px
  }
  &.big {
    .icon-logo {
      width 180px
      height @width
    }
  }
}
</style>
