<template>
  <div class=c-compose>
    <a-input v-model=subject placeholder=Assunto float />
    <div class=editor ref=root />
  </div>
</template>
<script>
import {onMounted,ref,watch,computed} from 'vue'
import {emailContent} from '@/store'
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
export default {
  props: {
    mode: {type:[Number,String], default:0},
    view: Object, // {name, month, year, part}
  },
  setup(props) {
    let quill
    const root    = ref(null)
    const subject = ref('')
    const content = computed(() => {
      let mode = props.mode
      if (typeof mode === 'string') {
        switch (mode) {
          case 'fp': // folha de pagamento
            mode = 0
            break
          case 'dt': // décimo terceiro
            mode = 1
            break
          case 'at': // aviso de que não enviou a última resposta
            mode = 2
            break
          default:
            mode = 0
            console.warn('Modo não reconhecido: `'+mode+'`. Usando Folha de Pagamento')
            break
        }
      }
      return emailContent[mode] || {subject: '', ops: {}}
    })

    onMounted(() => {
      quill = new Quill(root.value, {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'link','image'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['clean']
          ]
        }
      })
      updateContent()
      quill.on('text-change', () => {
        if (props.view) return; // read-only.
        content.value.ops = quill.getContents().ops
      })
    })

    const updateContent = () => {
      const ops = (props.view
        ? JSON.parse(R(JSON.stringify(content.value.ops)))
        : content.value.ops
      )
      quill?.setContents({ops: ops})
      subject.value = (props.view ? R(content.value.subject) : content.value.subject)
    }
    watch(() => props.mode, updateContent)
    watch(() => props.view, updateContent)

    watch(() => subject.value, v => {
      if (!props.view) content.value.subject = v
    })

    const R = (str) => { // replace
      const {name,month,year,part,competencia} = props.view
      return str
      .replaceAll('{{nome}}',  name)
      .replaceAll('{{mes}}',   month)
      .replaceAll('{{mês}}',   month)
      .replaceAll('{{ano}}',   year)
      .replaceAll('{{parcela}}', part)
      .replaceAll('{{competencia}}', competencia)
      .replaceAll('{{competência}}', competencia)
   }


    const getValues = () => ({
      subject: subject.value,
      html: root.value.querySelector('.ql-editor').innerHTML,
    })


    return {
      root,
      content,
      subject,
      getValues,
    }
  }
}
</script>
<style lang="stylus">
.c-compose {
  display flex
  flex-direction column
  & > .a-input {
    margin-bottom 12px
  }
  & > .ql-toolbar {
    flex 0 0 auto
  }
  & > .editor {
    flex 1 1 0%
    height 100%
    overflow auto
  }
}
</style>
