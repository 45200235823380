<template>
  <div class=hit-test ref=root></div>
</template>
<script>
import {onMounted,ref} from 'vue'
export default {
  emits: ['hit'],
  setup(props, {emit}) {
    const root = ref(null)
    const observer = new IntersectionObserver(change => {
      if (change[0].isIntersecting) emit('hit')
    }, {threshold: 1})
    onMounted(() =>
      observer.observe(root.value)
    )
    return {root}
  }
}
</script>
<style lang="stylus">
.hit-test {
  position absolute
  bottom calc(100vh - 40px)
  width 40px
  height @width
  pointer-events none
  background transparent
}
</style>
