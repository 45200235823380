import {extractCompetencia,competenciaToString} from 'i/utils'
import {getAddress} from 'i/utils'
import {ref,reactive} from 'vue'

export const logs = reactive([])
export const cDate = ref(null) // current date for logs
export const lDate = ref(null) // last date for querying
export const lquery = ref('')
export const resetLogs = () =>{
  logs.length = 0
  cDate.value = null
  lDate.value = null
  lquery.value = ''
}

export default async function load(context, monthStart=0, monthEnd=1) {
  const {emails, orphans, idMap, toMap} = context

  const date = context.date || cDate.value || new Date()
  const start = (new Date(date.getFullYear(), date.getMonth()+monthStart, 1)).toDateString() // inclusivo
  const end   = (new Date(date.getFullYear(), date.getMonth()+monthEnd  , 1)).toDateString() // exclusivo
  const query = [['SINCE', start], ['BEFORE', end]]

  const items = await Promise.all([
    window.$main.search(query, 'INBOX.Sent'),
    window.$main.search(query, 'INBOX'),
  ])
  const [sent,rece] = items

  for (const item of sent.reverse()) {
    item.competencia = competenciaToString(item.compet = extractCompetencia(item.subject))
    if (!idMap[item.id]) {
      emails.push(idMap[item.id] = item)
    }
    const to = getAddress(item.to)+' | '+item.competencia
    toMap[to] = item
  }

  // é possível obter a resposta antes de obter o parente. Por isso,
  // guardamos as respostas sem parente no `orphas` pra olhar de novo
  // sempre que recebermos mais parentes.
  const allRece = orphans.concat(rece)
  orphans.length = 0

  for (const item of allRece) {
    item.competencia = competenciaToString(item.compet = extractCompetencia(item.subject))
    if (!item.competencia) continue
    const from = getAddress(item.from)+' | '+item.competencia
    const parent = (item.parent && idMap[item.parent]) || toMap[from]
    if (!parent) {
      orphans.push(item)
      continue;
    }
    if (!parent.children) parent.children = []
    parent.children.push(item)
    // um email pode ter várias respostas. Respostas
    // com anexo sobrepõe-se às sem anexo no tipo
    // de resposta.
    if (item.struct.attachments.length) {
      parent.answerType = 2
    }
    else if (!parent.answerType) {
      parent.answerType = 1
    }
  }
}
