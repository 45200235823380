<template>
  <a-more class=a-color :class={left}>
    <template #handler><a-counter :background='colors[modelValue]' /></template>
    <a-counter v-for='(c,i) of copy' :key=i :background=c @click=update(i) />
  </a-more>
</template>
<script>
import {computed} from 'vue'
export const colors = [
  'red','#d502d5'/*purple*/,'blue',
  'orange','black','green',
]
export default {
  props: {
    modelValue: Number,
    left: Boolean,
    removable: Boolean, // se pode remover a cor
  },
  setup(props, {emit}) {
    const update = i => {
      const value = (props.removable && i === props.modelValue) ? null : i
      emit('update:modelValue', value)
    }
    const copy = computed(() => {
      if (!props.removable) return colors
      if (typeof props.modelValue !== 'number') return colors
      const copy = colors.slice()
      copy[props.modelValue] = null
      return copy
    })

    return {
      colors,
      copy, update,
    }
  }
}
</script>
<style lang="stylus">
.a-color {
  .a-counter {
    cursor pointer
  }
  .contents {
    text-align right
    display grid
    grid-gap 6px
    grid-template-columns repeat(3, 1fr)
  }
  &.left > .contents {
    border-top-left-radius 0px
    left 0px
    right inherit
    border-top-right-radius inherit
  }
}
</style>

