import {onMounted, onUnmounted, ref} from 'vue'
import {watch} from 'vue'

export function useScroll(el=document.documentElement) {
  const scroll = ref(0)
  let waiting = false
  const handler = () => {
    if (waiting) return;
    waiting = true
    requestAnimationFrame(() => {
      waiting = false
      let value = el.scrollTop
      if (value < 0) value = 0 // safari...
      scroll.value = value
    })
  }
  onMounted(() => {
    if (typeof el === 'string') el = document.querySelector(el)
    scroll.value = el.scrollTop
    window.addEventListener('scroll', handler, {passive: true})
  })
  onUnmounted(() => window.removeEventListener('scroll', handler))
  return scroll
}
export function useSwipe(start_, end_, el=document.documentElement) {
  const swipe = ref(0)

  let start = 0
  const down = e => {
    start_?.(swipe)
    start = (e.changedTouches ? e.changedTouches[0] : e).pageY
    el.addEventListener('mousemove', move, false)
    el.addEventListener('touchmove', move, false)
    el.addEventListener('mouseup', end, false)
    el.addEventListener('touchend', end, false)
  }
  const move = e => {
    const y = (e.changedTouches ? e.changedTouches[0] : e).pageY
    swipe.value = y - start
  }
  const end = () => {
    end_?.(swipe)
    el.removeEventListener('mousemove', move)
    el.removeEventListener('touchmove', move)
    el.removeEventListener('mouseup', end)
    el.removeEventListener('touchend', end)
  }

  onMounted(() => {
    el.addEventListener('mousedown', down, false)
    el.addEventListener('touchstart', down, false)
  })
  onUnmounted(() => end())

  return swipe
}
export function useScrollActivation(initialValue=true, downValue=false) {
  const active = ref(initialValue)
  const scroll = useScroll()
  watch(scroll, (novo, antigo) => {
    active.value = novo > antigo ? downValue : !downValue
  })
  return {active, scroll}
}


export function persistent(fn, key, defaultValue=undefined, fromString=x=>x, toString=x=>x) {
  const saved = localStorage.getItem(key)
  const value = fn(saved ? fromString(saved) : defaultValue)
  if (!saved && defaultValue !== undefined) {
    localStorage.setItem(key, toString(defaultValue))
  }
  watch(value, v => localStorage.setItem(key, toString(v)))

  return value
}

export function persistentRef(key, defaultValue=undefined, parse=x=>x) {
  return persistent(ref, key, defaultValue, parse)
}
