<template>
  <div id=page-cadastro>
    <div class=add-options>
      <c-funcionario template v-model=dummy @save=add addable />
      <a-button icon=upload title='Importar CSV' @click='csv=true' />
      <a-button icon=upload title='Baixar CSV' @click='download' />
    </div>

    <a-input placeholder=Pesquisar icon=search v-if=all.length v-model=query />
    <div class=lista>
      <c-funcionario v-for='fun of filtered' deletable :modelValue=fun :key=fun.code @remove='remove(fun)' />
    </div>
  </div>
  <c-import-csv v-model=csv @import=doImport />
</template>
<script>
import {funcionarios} from '@/store'
import {Funcionario,regexFromString} from 'i/utils'
import {computed,reactive,ref} from 'vue'
import {downloadFile} from 'i/utils'
export default {
  setup() {
    const dummy = reactive(Funcionario())
    const add = () => {
      dummy.code = dummy.code.padStart(6, '0')
      funcionarios.push({...dummy})
      dummy.clear()
    }
    const remove = (fun) => {
      const index = funcionarios.indexOf(fun)
      funcionarios.splice(index, 1)
    }

    const query = ref('')
    const filtered = computed(() => {
      const q = query.value.trim()
      const l = funcionarios.length
      if (!q || l === 0) return funcionarios

      const rgx = regexFromString(q)
      return funcionarios.filter(x =>
        x.code.includes(q) || rgx.test(x.email) || rgx.test(x.name) || rgx.test(x.place)
      ).reverse()
    })

    const csv = ref(false)
    const doImport = (items, replace) => {
      if (replace) {
        funcionarios.length = 0
        funcionarios.push(...items)
        return;
      }
      const seen = new Set()
      for (const fun of funcionarios) {
        seen.add(fun.code)
      }
      for (const fun of items) {
        if (seen.has(fun.code)) continue
        seen.add(fun.code)
        funcionarios.push(fun)
      }
    }

    const download = () => {
      const csv = 'código;local;email;nome\n' + funcionarios.map(f =>
        `${f.code};${f.place};${f.email};${f.name}\n`
      ).join('')
      const q = query.value.trim()
      downloadFile(
        new Blob([csv], {type: 'text/csv'}),
        'Relação funcionários'+(q ? ' - '+q : '')+'.csv'
      )
    }

    return {
      dummy, add, remove,
      all: funcionarios,
      query, filtered,
      csv, doImport,
      download,
    }
  }
}
</script>
<style lang="stylus">
#page-cadastro {
  display flex
  flex-direction column
  & > * {
    flex 0 0 auto
  }
  & > .lista {
    flex 1 1 auto
    overflow auto
    .c-funcionario:hover {
      background-color alpha(#7584CF, 0.2)
    }
  }
  & > .add-options {
    margin-bottom 60px
    display flex
    align-items center
    & > .c-funcionario {
      flex 1 1 auto
    }
    & > .a-button {
      margin-left 12px
      flex 0 0 auto
      margin-bottom 2px
      &:last-child {
        .a-icon {
          transform rotate(180deg)
        }
      }
    }
  }
  & > .a-input {
    margin-bottom 20px
  }
}
</style>
