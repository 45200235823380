<template>
  <a-row class=c-funcionario
    :selectable=selectable :deletable=deletable :addable=template :selected=selected :activable=activable
    @update:selected='$emit("update:selected",$event)'
    @save='$emit("save")' @remove='$emit("remove")'
    v-model:active=modelValue.active
  >
    <template v-if=template>
      <a-input class=cod v-model=modelValue.code placeholder=Código />
      <a-input class=cod v-model=modelValue.place placeholder=Local />
      <a-input class=cpf v-model=modelValue.email placeholder=Email />
      <a-input class=name v-model=modelValue.name placeholder=Nome @keyup.enter='$emit("save")' />
    </template>
    <template v-else>
      <m-editable :simple=compact v-model=modelValue.code  class=cod />
      <m-editable :simple=compact v-model=modelValue.place class=cod />
      <m-editable :simple=compact v-model=modelValue.email class=cpf v-if='!compact' type=email />
      <m-editable :simple=compact v-model=modelValue.name  class=name />
    </template>
  </a-row>
</template>
<script>
export default {
  props: {
    // {code, place, email, name, active}
    modelValue: {type: Object, required: true},
    selectable: Boolean,
    deletable: Boolean,
    activable: Boolean,
    template: Boolean,
    selected: Boolean,
    compact: Boolean,
  },
  emits: ['remove','save','update:selected'],
}
</script>
<style lang="stylus">
.c-funcionario {
  display flex
  gap 8px
  & > span {
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
  }
  .a-input.cpf,.a-input.name {
    border none
    border-radius 0
  }
  .a-input.cpf {
    border-right 1px solid $bg-gray
  }
  .cpf {
    flex auto 1 1
    padding 0px 12px
  }
  .cod {
    width 100px
  }
  .name {
    flex 180px 0 0
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
  }
  +mobile() {
    &.add {
      display grid
      grid-template-columns 1fr 1fr
      grid-row-gap 12px
      .cpf,.name {
        width unset
        border-right none !important
        border-bottom 1px solid $bg-gray !important
        grid-column-start 1
        grid-column-end 3
      }
    }
    &:not(.add) {
      .cpf {
        display none
      }
    }
  }
}
</style>
