<template>
  <div id=page-home :class='{empty:!url}'>
    <div class=preview-container>
      <div class=options>
        <a-file-upload button @upload=uploaded>Enviar outro PDF</a-file-upload>
        <a-button @click=load :disabled='loading===0'>Processar</a-button>
      </div>
      <c-preview ref=preview />
    </div>
    <c-found-list @show=show @confirm=proximo />

    <a-file-upload @upload=uploaded v-if='!url' allowed=pdf>
      Para começar,<br/>
      arraste um arquivo aqui,<br/>
      ou clique para selecionar.
    </a-file-upload>

  </div>
  <a-modal v-model=next title='Confirmar e enviar' :class-name="loading ? 'send-modal loading' : 'send-modal'">
    <a-tabs headers='Normal,Não recebido' v-model=emailTab>
      <c-compose ref=compose :mode=mode :view=viewData />
    </a-tabs>
    <div class=options>
      <a-button @click=send>Enviar</a-button>
    </div>
    <div class=loading v-if=error>
      <p>Erro</p>
      <p>{{error}}</p>
    </div>
    <div class=loading v-else-if='loading===2'>
      <p>Obtendo lista dos recebidos...</p>
      <p><a-icon name=spinner /></p>
    </div>
    <div class=loading v-else-if='loading || finished'>
      <template v-if=finished>
        <p><a-icon name=send /></p>
        <p>Emails enviados!</p>
        <p>Você pode confirmar o envio na aba do histórico.</p>
      </template>
      <template v-else>
        <p>Enviando emails. Não feche essa aba.</p>
        <p><a-icon name=spinner /></p>
        <p>Enviando para <b>{{current}}</b></p>
      </template>
    </div>
  </a-modal>
</template>
<script>
import {ref,computed} from 'vue'
import {pdfUrl,config,viewData} from '@/store'
import {resetLogs} from '@/store'

import {competenciaToString} from '@/imports/utils'
import {getAddress,partition} from '@/imports/utils'
import {sleep,extractCompetencia} from '@/imports/utils'

import Load from '@/store/logs'
export default {
  setup() {
    const current = ref('')
    const finished = ref(false)

    const compose  = ref(null)
    const emailTab = ref(0)
    const mode     = computed(() => emailTab.value ? 'at' : (viewData.value?.part ? 'dt' : 'fp'))

    const uploaded = file => {
      // yyyy-mm-(pf|13.(1|2))
      const numbers = (file.name.match(/\d+/g) || [])
      viewData.value = {
        year: numbers[0],
        month: numbers[1],
        part: numbers[3] || 0
      }
      pdfUrl.value  = URL.createObjectURL(file)
    }

    const loading = ref(0)
    const error   = ref(null)
    const preview = ref(null)
    const load = () => {
      loading.value = 1
      preview.value.process()
      .finally(() => loading.value = 0)
    }
    const show = index => preview.value.renderPage(index+1)

    const next = ref(false)
    const emails = ref([])
    const proximo = active => {
      error.value = ''
      finished.value = false
      next.value = true
      emails.value = active.map(x => [x.name + ' <'+x.email+'>', x.index, x.email])
    }
    const send = async () => {
      const context = {
        idMap: Object.create(null),
        toMap: Object.create(null),
        orphans: [],
        emails: [],
        date: new Date(),
      }
      const copyData = {...viewData.value}
      try {
        loading.value = 2
        await Load(context, /*início mês passado*/-1, /*fim desse mês*/1)
        loading.value = 1

        const sent = new Set() // pra evitar enviar de novo
        const currentCompetencia = competenciaToString([copyData.month, copyData.year, copyData.part])

        const unanswered = Object.create(null)
        for (const email of context.emails) {
          if (email.answerType !== 2) { // 2 é respondido com anexo
            if (currentCompetencia !== email.competencia) {
              //unanswered[getAddress(email.to)] = email.competencia
            }
          }
          sent.add(getAddress(email.to) + ' | ' + email.subject) // registra os já enviados
        }

        const [bad,good] = partition(emails.value, item =>
          unanswered[item[2]]
        )

        emailTab.value = 0
        await sleep(100) // espera atualizar
        if (good.length) await send_to(good, sent)
        if (bad.length) {
          // como podemos ter mais de uma competência faltando,
          // vou organizar os dados pra isso.
          const competencias = Object.create(null)
          for (const [addr,comp] of Object.entries(unanswered)) {
            competencias[comp] = competencias[comp] || []
            const mail = bad.find(x => x[2] === addr)
            if (mail) competencias[comp].push(mail)
          }
          const cc = config.supervisor ? [config.supervisor] : []

          emailTab.value = 1
          for (const [competencia,arr] of Object.entries(competencias)) {
            const comp = extractCompetencia(competencia)
            viewData.value = {month: comp[0], year: comp[1], part: comp[2], competencia}
            await sleep(100) // pra a UI atualizar.
            await send_to(arr, sent, cc)
          }
        }
      }
      catch (e) {
        error.value = e
        console.error(e)
      }

      viewData.value = {...copyData}
      finished.value = true
      emailTab.value = 0
      loading.value = 0
      resetLogs()
    }
    const send_to = async (emails, sent, cc=[]) => {
      const values = compose.value.getValues()
      for (const [email,index] of emails) {
        await show(index)
        const data_url = await preview.value.getPage()
        current.value = email
        const name = email.split('<')[0].trim().split(/\s+/).slice(0,2).join(' ')
        const key  = getAddress(email) + ' | ' + values.subject
        if (sent.has(key)) {
          console.log('already sent to ', email, values.subject)
          continue
        }

        await window.$main.send(config.name, email, values.subject, values.html, [{
          filename: `${viewData.value?.year}-${viewData.value?.month} ${name}.pdf`,
          path: data_url,
        }], cc)
      }
      current.value = ''
    }

    return {
      uploaded, url: pdfUrl,
      preview, load, loading,
      show, next, proximo, send,
      compose, mode, viewData,
      current, finished,
      emailTab, error,
    }
  }
}
</script>
<style lang="stylus">
#page-home {
  position relative
  display flex
  overflow hidden
  +mobile() {
    flex-direction column-reverse
    & > .c-found-list {
      padding-bottom 60px
      margin-bottom 60px
    }
  }
  & > .a-file-upload {
    top 50%
    left 50%
    position absolute
    transform translate(-50%, -50%)
    z-index 1
    box-shadow 0px 0px 0px 1000px $background
  }

  & > .c-found-list {
    flex 1 0 50%
    min-width 'min(%spx, 100%)' % 500 // em string pra evitar que o min seja a função do stylus
  }
  & > .preview-container {
    flex 1 1 50%
    overflow hidden
    display flex
    flex-direction column
    margin-right 20px
    & > .options {
      flex 0 0 auto
      display flex
      justify-content flex-start
      .a-button {
        margin-left 12px
      }
    }
    & > .c-preview {
      flex 1 1 0%
    }
  }

  &.empty {
    & > .preview-container, & > .c-found-list {
      pointer-events none
    }
  }
}
.a-modal.send-modal {
  display flex
  flex-direction column
  & > .c-compose {
    flex 1 1 0%
  }
  & > .a-modal-title {
    flex 0 0 auto
  }
  & > .options {
    margin-top 12px
    text-align right
  }
  & > .close {
    z-index 1
  }
  &.loading > .close {
    z-index 0
  }
  & > .loading {
    top 0
    left 0
    width 100%
    height 100%
    display flex
    position absolute
    align-items center
    flex-direction column
    justify-content center
    background rgba($background, 0.95)
  }
}
</style>
