<template>
  <div class=a-counter :class=Class :style=Color>
    <slot/>
  </div>
</template>
<script>
import {computed} from 'vue'
export default {
  props: {
    // se o background for 'gray', usa o $bg-gray de vars.styl
    // se não for especificado, usa o $base de vars.styl
    background: String,
  },
  setup(props) {
    const Class = computed(() => ({'bg-gray': props.background === 'bg-gray'}))
    const Color = computed(() => ({'background-color': !Class.value['bg-gray'] && props.background}))

    return {
      Class, Color,
    }
  }
}
</script>
<style lang="stylus">
.a-counter {
  height 24px
  min-width @height
  background-color $base
  color $contrast
  border-radius 6px
  display inline-flex
  vertical-align middle
  align-items center
  justify-content center
  font-size 17px
  cursor default
  user-select none
  &.bg-gray {
    background-color $bg-gray
  }
}
</style>
