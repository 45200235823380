<template>
  <span class=timing>{{time}}</span>
</template>
<script>
import {momento} from 'i/utils'
import {watch, onMounted, onUnmounted, ref} from 'vue'
export default {
  props: {
    value: {type: [Date,Number,String], required: true},
  },
  setup(props) {
    let timer
    const time = ref('')
    const update = () => time.value = momento(props.value)

    onMounted(() => {
      timer = setInterval(update, 60000)
      update()
    })
    onUnmounted(() => clearInterval(timer))
    watch(() => props.value, update)

    return {time}
  },
}
</script>
