<template>
  <div class=a-input>
    <a-icon v-if=icon :name=icon />

    <input :id=id v-if=mask v-maska :data-maska=mask
      :type=type :placeholder=pholder :name=name :value=modelValue :disabled=disabled
      @input='$emit("update:modelValue", $event.target.value)'
      ref=root
    />
    <textarea :id=id v-else-if='type==="textarea"'
      :type=type :placeholder=pholder :name=name :value=modelValue
      :disabled=disabled @input='$emit("update:modelValue", $event.target.value)'
      ref=root
    />
    <input :id=id v-else
      :type=type :placeholder=pholder :name=name :value=modelValue
      :disabled=disabled @input='$emit("update:modelValue", $event.target.value)'
      ref=root
    />

    <label :for=id v-if='placeholder&&float'>{{placeholder}}</label>
  </div>
</template>
<script>
import { vMaska } from "maska"
import {computed} from 'vue'
import {onMounted,ref} from 'vue'
export default {
  props: {
    icon: String,
    type: {type: String, default: 'text'},
    placeholder: {type: String, default: ''},
    name: String,
    disabled: Boolean,
    modelValue: [String,Number],
    float: Boolean,
    mask: [String,Array], // docs: https://beholdr.github.io/maska/#/
    initWithFocus: Boolean,
  },
  emits: ['update:modelValue'],
  directives: { maska: vMaska },
  setup(props) {
    const id = Array.from({length: 10})
      .map(() => Math.floor(Math.random()*26))
      .map(i => String.fromCharCode(65+i))
      .join('')
    const pholder = computed(() => props.float ? ' ' : props.placeholder)
    const root = ref(null)

    onMounted(() => {
      if (props.initWithFocus) {
        console.log('ye')
        root.value?.focus()
      }
    })

    return {
      id, pholder,
      root,
    }
  }
}
</script>
<style lang="stylus">
.a-input {
  border-radius 8px
  border 1px solid $bg-gray
  display flex
  align-items center
  overflow hidden
  padding 0px 9px
  height $h = $input-height
  input,textarea {
    flex 0% 1 1
    height 100%
    line-height $h
    border none
    min-width 0
    outline none
    font-size inherit
    font-style inherit
    font-family inherit
  }
  textarea {
    height 3 * $h
    resize vertical
  }
  .a-icon {
    margin-left 6px
    stroke $fg-gray
  }
  .a-icon + input {
    margin-left 12px
  }
}
// pra fazer o floating-placeholder
.a-input {
  position relative
  overflow visible
  label {
    position absolute
    top -8px
    left 6px
    font-size 90%
    padding 0px 8px
    background-color $background
    cursor text
    opacity 0.8
    user-select none
    transform translate(-2px, 17px) scale(1.1)
    transform-origin left center
    transition transform .1s ease-out, opacity .1s ease-out
  }
  input, textarea {
    &:not(:placeholder-shown), &:focus {
      & + label {
        cursor default
        transform none
        opacity 1
        color #4d4d4d
      }
    }
  }
}
</style>
