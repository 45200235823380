<template>
  <teleport to=body>
    <div class=a-modal-background v-if=modelValue @click.self.stop.prevent=close>
      <div class=a-modal :class=[className]>
        <button @click=close class=close>×</button>
        <h2 v-if=title class=a-modal-title>{{title}}</h2>
        <h2 v-else-if='$slots.title' class=a-modal-title>
          <slot name=title />
        </h2>
        <p class=a-modal-subtitle v-if='$slots.subtitle'>
          <slot name=subtitle />
        </p>
        <slot />
      </div>
    </div>
  </teleport>
</template>
<script>
import {watch} from 'vue'

let stack = 0
export default {
  props: {
    modelValue: Boolean,
    className: String,
    title: String,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    let position
    const close = e => {
      if (e instanceof KeyboardEvent) {
        if (e.keyCode !== 27) return;
        if (position !== stack) return;
      }
      emit('update:modelValue', false)
    }
    watch(() => props.modelValue, v => {
      if (v) {
        position = ++stack
        document.addEventListener('keyup', close, {passive: true})
        document.body.classList.add('no-scroll')
      }
      else {
        stack -= 1
        position = null
        document.removeEventListener('keyup', close)
        if (stack <= 0) document.body.classList.remove('no-scroll')
      }
    })

    return {
      close,
    }
  }
}
</script>
<style lang="stylus">
.a-modal-background {
  top 0
  left 0
  width 100%
  height 100%
  position fixed
  background-color rgba(0,0,0,0.1)
  z-index 1
}
.a-modal {
  width 80vw
  height auto
  min-height 50vh
  max-height 90vh
  border-radius 18px
  background-color white
  box-shadow 0px 0px 18px 1px rgba(0,0,0,0.4)
  padding 80px 53px 30px 53px
  overflow-y auto

  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)

  & > .close:first-child {
    position absolute
    top 20px
    right 20px
    background transparent
    border none
    font-size 26px
    user-select none
    cursor pointer
  }
  & > .a-modal-title {
    margin-top -40px
    margin-bottom 40px
    & + .a-modal-subtitle {
      margin-top -32px
      margin-bottom 40px
    }
  }
  & > .a-modal-subtitle {
    margin-top 0px
    font-weight 100
  }
  +mobile() {
    width 100vw
    transform none
    top 80px
    height 'calc(100% - %s)' % (@top)
    left 0%
    border-bottom-left-radius 0
    border-bottom-right-radius 0
    padding 40px 24px 30px 24px
    & > .close:first-child {
      top 16px
    }
    & > .a-modal-subtitle {
      margin-bottom 40px
      margin-top 0px !important
    }
  }
}
</style>
