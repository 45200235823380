<template>
  <div class='c-mail-reader empty' v-if=!header>
    <p>Clique em um email para vê-lo aqui</p>
  </div>
  <div class=c-mail-reader v-else>
    <div class=item v-for='(head,i) of items' :key=head.uid :class='{expanded:expand[i]}'>
      <ul class=headers>
        <li><b>Para: </b><span>{{head.to}}</span></li>
        <li><b>Data: </b><span>{{head.date?.toLocaleDateString()}} {{head.date?.toLocaleTimeString()}}</span></li>
        <li><b>Assunto: </b><span>{{head.subject}}</span></li>
        <li>
          <b>Anexo: </b>
          <span class=attch v-for='(attch,j) of head.struct.attachments' :key=j @click='download(i,head,attch)'>
            <a-icon name=spinner v-if='downloading===(head.uid+attch.partID)' /> {{attch.params?.name}}
          </span>
        </li>
      </ul>
      <a-icon name=spinner v-if=head.loading />
      <p class=error v-if=head.error>{{head.error}}</p>
      <template v-if=head.body>
        <iframe v-if='expand[i]' class=content :src=head.body onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px'" />
        <div class=expand @click='expand[i]=!expand[i]'/>
      </template>
    </div>
  </div>
</template>
<script>
import {computed,reactive,watch} from 'vue'
import {downloadAttchBlob} from 'i/utils'
import {downloadFile} from 'i/utils'
import {ref} from 'vue'
export default {
  props: {
    header: Object,
  },
  setup(props) {
    const items = computed(() =>
      props.header && [props.header].concat(props.header.children || []).map(x => reactive(x))
    )
    const expand = computed(() => {
      if (!items.value) return []
      const arr = Array(items.value.length).fill(false)
      arr[arr.length-1] = true
      return reactive(arr)
    })
    const load = () => {
      if (!items.value) return;
      for (const [index,header] of items.value.entries()) {
        if (header.body) continue
        if (header.error) header.error = null
        header.loading = true

        const part = header.struct.text
        window.$main.details(
          index === 0 ? 'INBOX.Sent' : 'INBOX', // box
          {uid: header.uid}, // message
          {partID: part.partID, encoding: part.encoding}, // part
        )
        // transforma em URL porque, daí, eu posso colocar tudo num iframe.
        // Isso porque o email vem com CSS, e não quero que esse CSS afete
        // a página principal
        .then(response => URL.createObjectURL(new Blob([response], {type: 'text/html;charset=utf-8'})))
        .then(response => header.body = response)
        .catch(e => header.error = e)
        .finally(() => header.loading = false)
      }
    }
    const downloading = ref(null)
    const download = (box, header, part) => {
      return downloadAttchBlob(box, header, part)
      .then(blob => downloadFile(blob, blob.name))
      .catch(e => alert(e))
      .finally(() => downloading.value = null)
    }
    watch(items, load)

    return {
      items, download, expand,
      downloading,
    }
  },
}
</script>
<style lang="stylus">
.c-mail-reader {
  &.empty {
    background-color $bg-gray
    display flex
    align-items center
    justify-content center
    user-select none
  }

  & > .item {
    &:last-child {
      height auto
    }
    & > .headers {
      span {
        font-size 96%
      }
    }
    & > .headers .attch {
      border 1px solid $bg-gray
      border-radius 6px
      padding 2px 4px
      cursor pointer
      display inline-block
      vertical-align middle
      & + .attch {
        margin-left 8px
      }
    }
    & > .a-icon {
      display block
      margin-left auto
      margin-right auto
      margin-top 40px
    }
    & > .content {
      padding 0px 24px
      width 100%
      & > .error {
        padding 0px 30px
      }
    }
    & > .error {
      color $fg-danger
    }

    $h = 30px
    $g = $h/2.5
    & > .expand {
      height $h
      border-top 1px solid $bg-gray
      position relative
      margin-top 24px
      &::after {
        content ' '
        width 2*$h
        height $h
        margin-left auto
        margin-right auto
        display block
        background-color $background
        border-radius 0 0 $h $h
        border 1px solid $bg-gray
        border-top none
        margin-top -1px
        cursor pointer
      }
      &::before {
        content ' '
        width 0
        height 0
        border-left $g solid transparent
        border-right $g solid transparent
        border-top $g solid $base
        border-bottom 0 solid $base
        position absolute
        left 50%
        top 50%
        pointer-events none
        transform translate(-50%, -80%)
      }
    }
    &.expanded {
      & > .expand::before {
        border-bottom-width $g
        border-top-width 0
      }
    }
  }
}
</style>
