<template>
  <a-modal :modelValue=modelValue @update:modelValue='$emit("update:modelValue", $event)' title='Importar CSV' class-name='c-import-csv'>
    <a-file-upload @upload=uploaded v-if=!items allowed='txt,csv' decode string>
      Arraste um arquivo CSV aqui<br/>
      ou clique para selecioná-lo.
    </a-file-upload>
    <template v-else>
      <div class=add-options>
        <a-button @click='send(false)'>Adicionar aos existentes</a-button>
        <a-button @click='send(true)' danger>Substituir os existentes</a-button>
      </div>
      <c-funcionario v-for='fun of items' :modelValue=fun :key=fun.code  />
    </template>
  </a-modal>
</template>
<script>
import {ref,watch} from 'vue'
import {Funcionario} from 'i/utils'
export default {
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue', 'import'],
  setup(props, {emit}) {
    const items = ref(null)
    const uploaded = async value => {
      const pv = value.match(/;/g)?.length || 0
      const vv = value.match(/,/g)?.length || 0
      const se = pv > vv ? ';' : ','
      const re = value.split('\n')
        .slice(1) // headers
        .map(x => x.trim().split(se))
        .filter(x => x.map(x => x.length).reduce((p,c) => p + c, 0) > 0)
        .map(x => Funcionario({code: x[0], place: x[1], email: x[2], name: x[3], active: true}))
      items.value = re
    }
    watch(() => props.modelValue, v => {
      if (v) items.value = null
    })
    const send = replace => {
      emit('import', items.value, replace)
      emit('update:modelValue', false)
    }
    
    return {
      items, uploaded,
      send,
    }
  }
}
</script>
<style lang="stylus">
.c-import-csv {
  .a-file-upload {
    margin-left auto
    margin-right auto
  }
  .add-options {
    margin-bottom 40px
    display flex
    justify-content space-around
  }
}
</style>
