<template>
  <a-input class=m-editable @keyup.enter=change @keyup.esc=clear
    v-model=value v-if=active init-with-focus :type=type
  />
  <span v-else class=m-editable @dblclick=set>{{modelValue}}</span>
</template>
<script>
import {watch} from 'vue'
import {ref} from 'vue'
export default {
  props: {
    modelValue: {type: String, required: true},
    type: {type: String, default: 'text'},
    simple: Boolean,
  },
  setup(props, {emit}) {
    const active = ref(false)
    const value = ref('')

    watch(() => props.modelValue, v => { value.value = v }, {immediate: true})
    const change = () => {
      emit('update:modelValue', value.value)
      active.value = false
    }
    const clear = () => {
      active.value = false
      value.value = props.modelValue
    }
    const set = () => {
      if (!props.simple) active.value = true
    }


    return {
      set,
      active,
      value, change, clear,
    }
  }
}
</script>
<style lang="stylus">
.m-editable {
  &.a-input, & > input {
    background-color #dddddd
  }
  &.a-input {
    border 1px solid gray !important
    border-radius 6px !important
  }
}
</style>
