<template>
  <div class=a-row :class={add:!selectable}>
    <a-checkbox :modelValue=selected @update:modelValue='$emit("update:selected", $event)' v-if=selectable />
    <slot />
    <a-switch :modelValue=active @update:modelValue='$emit("update:active", $event)' v-if=activable />
    <a-more v-if=deletable>
      <a-button warn fill @click='$emit("remove")'>Excluir</a-button>
    </a-more>
    <a-button v-else-if=addable class=add @click='$emit("save")'>
      <a-icon name=plus />
    </a-button>
  </div>
</template>
<script>
export default {
  props: {
    selectable: Boolean,
    selected: Boolean,
    deletable: Boolean,
    addable: Boolean,

    activable: Boolean,
    active: Boolean,
  },
  emits: ['remove','save','update:selected','update:active'],
}
</script>
<style lang="stylus">
.a-row {
  display flex
  align-items center
  padding 6px 0px
  padding-left 6px
  border-bottom 1px solid $bg-gray
  cursor pointer
  &:hover {
    background-color #EEE
  }
  &.add:hover {
    background-color unset
  }
  & > .a-checkbox:first-child {
    margin-right 12px
  }
  & > .a-switch {
    margin-left 12px
  }
  & > * {
    flex-grow 0
    flex-shrink 0
  }
  .a-more {
    margin-left 12px
    &.active span {
      background-color $fg-warn !important
      border-color $fg-warn !important
    }
    .a-button {
      width 100px
    }
  }
  .a-button.add {
    margin-left 12px
  }
}
</style>
