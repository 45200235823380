<script>
const icons = {
  highlight(h) {
    return [
      h('path', {d:'m58.729 14.118-18.021 18.505c-2.1926 2.7806-1.8932 6.1961-1.215 9.263l26.726 26.854c3.8933 1.3435 6.7104 0.9584 9.9441-1.2729l19.532-19.399'}),
      h('path', {d:'m39.492 41.885-34.984 35.773-0.20253 8.2237 44.212-0.58719 17.7-16.555'})
    ]
  },
  filter(h) {
    return [
      h('path', {d:'m13.013 2.8695 0.06273 12.373 29.014 34.586 0.17047 47.302 15.99-9.6983 0.12033-37.841 28.614-34.135 2e-3 -12.57z'}),
      h('path', {d:'m13.076 14.098 73.909 0.21426'}),
    ]
  },
  search(h) {
    return [
      h('ellipse', {cx:'41.03', cy:'40.022', rx:'38.495', ry:'37.487', c:'41.022'}),
      h('path', {d:'m68.102 67.683 29.265 30.082'}),
    ]
  },
  logo(h) {
    return [
      h('rect', {x:'1.2195', y:'1.2195', width:'97.561', height:'97.561', rx:'19.512'}),
    ]
  },
  list(h) {
    return [
      h('path', {d:'m94.141 21.245-67.142-0.033681'}),
      h('path', {d:'m94.141 50.448-67.142-0.033681'}),
      h('path', {d:'m94.141 79.504-67.142-0.033681'}),
      h('ellipse', {cx:'9.896', cy:'21.079', rx:'4.2284', ry:'4.2284'}),
      h('ellipse', {cx:'9.8877', cy:'50.094', rx:'4.2284', ry:'4.2284'}),
      h('ellipse', {cx:'9.8595', cy:'78.921', rx:'4.2284', ry:'4.2284'}),
    ]
  },
  users(h) {
    return [
      h('path', {d:'m52.486 13.002c-5.3564 1.6992-23.882 3.7967-17.578 24.926-0.45115 0.78558-1.1123 1.0234-1.3711 1.8418-0.42241 1.3359-0.69031 3.3218 1.1289 6.0742 0.40795 0.61722 1.5991 0.99769 2.2363 1.6504 0.20625 4.1337 1.9957 6.4928 3.8965 8.7891 0.33099 2.7367 0.17962 5.3884-0.36719 8.3203-5.6487 5.4179-17.198 8.0496-21.033 12.062-2.6076 2.9514-4.7415 6.0916-4.1152 10.332l68.855-0.04102c-0.30825-3.6881-0.8947-7.3933-4.6465-11.287-7.5587-6.0911-15.685-4.9097-20.529-11.684-0.73843-2.3158-0.51384-5.1606-0.48633-7.8965 2.7979-2.4722 3.4904-5.6627 4.2832-8.8203 0.50066-0.69495 1.724-0.69704 2.0664-1.2637 1.4067-2.3282 1.7967-4.5899 1.3457-5.8809-0.35828-1.0256-1.42-1.1707-1.6895-2.1699 2.5176-12.47-0.70244-19.399-9.5391-20.574z'}),
      h('path', {d:'m84.86 80.735 13.484 0.02806c0.25449-2.9305-1.7315-7.1297-3.8867-8.6407-9.4374-4.9814-13.865-6.6738-15.159-8.6498-1.2698-2.8653-0.29303-4.6565-0.29524-6.6797 1.7314-2.4514 2.1161-4.9481 2.6388-7.098l1.8506-0.25883c1.0752-1.3168 1.2321-3.7681 1.3435-5.5278l-1.6296-1.2808c2.0571-6.9305 1.9162-14.229-6.9646-15.725l-0.93248-3.0081c-3.0733 0.34266-6.3512 0.24316-8.0523 0.88006'}),
      h('path', {d:'m14.595 80.895-12.966 0.0279c-0.2447-2.9148 1.6649-7.0915 3.7372-8.5944 9.0744-4.9547 13.331-6.638 14.576-8.6034 1.2209-2.8499 0.28177-4.6316 0.28388-6.6439-1.6648-2.4383-2.0347-4.9215-2.5373-7.06l-1.7794-0.25745c-1.0339-1.3097-1.1847-3.7479-1.2918-5.4982l1.567-1.2739c-3.6326-8.3934 0.20464-18.923 13.166-19.373 1.6373-0.05682 1.2475 1.3732 1.8302 2.8128'}),
    ]
  },
  config(h) {
    return [
      h('ellipse', {cx:"50.003", cy:"49.944", rx:"14.414", ry:"14.369"}),
      h('path', {d:"m43.997 97.999 12.062-0.010494 2.5717-14.025 9.4477-3.6623 11.554 7.6178 7.8605-8.2279-7.7124-11.502 3.9685-9.8705 14.392-2.8235 0.091817-10.576-14.735-2.9662-3.7939-9.7272 8.4602-11.962-8.3285-7.864-11.43 7.3498-10.055-3.7971-2.7224-13.71-11.608-0.023763-2.2297 13.252-9.4857 4.4487-11.465-7.421-8.3564 7.6423 7.7756 12.129-3.7079 8.4212-14.002 3.467-0.21534 11.741 13.755 2.816 4.0032 9.4974-8.1477 11.047 8.6207 8.8458 11.472-8.1948s4.2445 1.9656 9.9559 4.0526z"}),
    ]
  },
  bell(h) {
    return [
      h('path', {d:'m1.1274 84.043h97.793c-15.815-8.0869-16.92-26.738-17.101-46.341-0.055384-6.007-2.3764-11.887-5.595-16.48-4.5601-7.4174-12.101-10.925-20.063-13.168-0.5419-1.438-0.79949-3.0146-2.5621-4.3788-2.3976-1.6737-4.8853-1.6831-7.1339 0.037725-1.4429 1.1181-1.7844 1.5901-2.4774 4.341-13.709 3.3312-19.221 10.808-21.973 15.993-2.3794 6.269-3.4037 13.057-3.4673 16.919-0.34941 21.211-2.249 34.874-17.42 43.077z'}),
      h('path', {d:'m59.876 90.722-19.595 0.03847a9.7974 6.8157 0 0 0 9.8242 6.777 9.7974 6.8157 0 0 0 9.7705-6.8155z'}),
    ]
  },
  triangle(h) {
    return [
      h('path', {d:'m90.964 22.829-40.964 52.031-40.964-52.031z'}),
    ]
  },
  spinner(h) {
    return [
      h('path', {d: 'm96.852 47.209c-0.83261-16.991-11.101-32.867-26.366-40.109-16.652-7.5204-36.912-4.4565-50.789 7.2419-13.322 11.142-19.428 29.246-15.542 46.237 3.608 16.712 16.652 30.36 33.027 34.817 19.705 5.5707 37.745-3.8995 48.846-20.055-9.9913 13.37-25.256 22.283-42.185 19.219-16.929-3.0638-30.807-15.877-34.692-32.589-4.1631-17.826 4.1631-36.488 19.983-45.679 16.375-9.4705 39.41-5.8493 50.234 10.306 2.7754 3.8995 4.7181 8.6346 5.5507 13.37 0.83256 3.8995 0.55511 8.0775 1.1101 11.977 0.55511 3.621 3.608 8.356 7.771 5.8493 3.608-2.2283 3.3304-6.9633 3.0529-10.584 0-1.1141 0.27754 1.9498 0 0z'})
    ]
  },
  send(h) {
    return [
      h('g', {transform:'translate(-1.02 9.74)'}, [
        h('path', {d:'m4.33 8.64 38.6 28.2c3.55 2.01 7.13 2.48 10.7 0.0491l38.1-28.2'}),
        h('path', {d:'m66.7 68.5-62.3 0.0073v-59.9l87.5 6.8e-6 -0.0808 19.6'}),
        h('path', {d:'m74.2 37.9 23.1 17.1-23.1 17.1'}),
        h('path', {d:'m97.3 54.9h-46.4'}),
      ])
    ]
  },
  plus(h) {
    return [
      h('line', {x1:'49', x2:'49', y1:'20.125', y2:'77.875'}),
      h('line', {x1:'20.125', x2:'77.875', y1:'49', y2:'49'}),
    ]
  },
  upload(h) {
    return [
      h('g', {transform:"translate(-1.02 9.74)"}, [
        h('path', {d:"m6.02 74.3 90-0.0372"}),
        h('path', {d:"m51 71.4v-62.9"}),
        h('path', {d:"m23.6 38.7 27.4-30.1 27.4 30.1"}),
      ])
    ]
  },
  download(h) {
    return icons.upload(h)
  }
}
import {h} from 'vue'
export default {
  props: {
    name: String,
  },
  setup(props) {
    return () => h('svg', {
      version: '1.1',
      viewBox: '0 0 100 100',
      class: {active: props.active, 'a-icon': true, ['icon-'+props.name]: true, fill: props.fill},
    }, icons[props.name]?.(h) ?? [])
  }
}
</script>
<style lang="stylus">
.a-icon {
  width 24px
  height @width
  display inline-block
  vertical-align middle
  fill transparent
  stroke black
  stroke-width 5
  transition fill
  stroke-linejoin round
  stroke-linecap round
  &.fill {
    fill black
  }
  &.icon-download {
    transform rotate(180deg)
  }
  &.icon-spinner {
    fill $base
    stroke-width 0
    animation spin .5s linear infinite
  }
}
@keyframes spin {
  0% {
    transform rotate(0deg)
  }
  100% {
    transform rotate(360deg)
  }
}
</style>
