import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/ui/pages/home.vue'
import Logs from '@/ui/pages/logs.vue'
import Cada from '@/ui/pages/cadastro.vue'
import Conf from '@/ui/pages/config.vue'

export const routes = [
  {
    path: '/',
    name: 'home',    // used in menu
    icon: 'logo',    // used in menu
    label: 'Email', // used in menu
    component: Home,
  },
  {
    path: '/logs',
    name: 'Logs',
    icon: 'list',
    label: 'Histórico',
    component: Logs,
  },
  {
    path: '/users',
    name: 'cadastro',
    icon: 'users',
    label: 'Cadastro',
    component: Cada,
  },
  {
    path: '/config',
    name: 'config',
    icon: 'config',
    label: 'Configurações',
    component: Conf,
  },
]
const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
