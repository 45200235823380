<template>
  <site-menu v-if='menuType === 0' />
  <site-contents :tabs='menuType===1' />
</template>
<script>
import Menu from '@/ui/menu'
import Cont from '@/ui/contents'
export default {
  components: {siteMenu: Menu, siteContents: Cont},
  setup() {
    const menuType = 1

    return {
      menuType,
    }
  }
}
</script>
<style lang="stylus">
@import '~@/ui/base.styl'
</style>
