<template>
  <div class=a-switch :class={active:modelValue} @click.stop="$emit('update:modelValue', !modelValue)">
    <div class=handle />
  </div>
</template>
<script>
export default {
  props: {
    modelValue: Boolean,
  },
}
</script>
<style lang="stylus">
.a-switch {
  width $w = 51px
  height $h = 28px
  border-radius 18px
  background-color $bg-gray
  transition background-color $time ease
  padding $p = 2px
  cursor pointer
  display inline-block
  vertical-align middle
  & > .handle {
    width ($h - 2*$p)
    height @width
    border-radius 50%
    background-color $contrast
    transition transform $time ease
  }

  &.active {
    background-color $base
    & > .handle {
      // vai de uma ponta a outra
      // padding + handle_translate + handle_width + padding = width
      // handle_translate = width - (padding + handle_width + padding)
      transform 'translateX(%s)' % ($w - 2*$p - ($h - 2*$p))
    }
  }
}
</style>
