import Preview from './preview.vue'
import Compose from './compose.vue'
import Funcionario from './funcionario.vue'
import FoundList from './found-list'
import MailReader from './mail-reader.vue'
import ImportCSV from './import-csv.vue'

export default function install(Vue) {
  Vue.component('c-preview', Preview)
  Vue.component('c-compose', Compose)
  Vue.component('c-funcionario', Funcionario)
  Vue.component('c-found-list', FoundList)
  Vue.component('c-mail-reader', MailReader)
  Vue.component('c-import-csv', ImportCSV)
}
