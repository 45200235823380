import Row from './row.vue'
import More from './more.vue'
import Logo from './logo.vue'
import Icon from './icon.vue'
import Tabs from './tabs.vue'
import Modal from './modal.vue'
import Badge from './badge.vue'
import Input from './input.vue'
import Button from './button.vue'
import Switch from './switch.vue'
import Timing from './timing.vue'
import Counter from './counter.vue'
import HitTest from './hit-test.vue'
import Checkbox from './checkbox.vue'
import FileUpload from './file-upload.vue'
import Color from './color.vue'

export default function install(Vue) {
  Vue.component('a-row', Row)
  Vue.component('a-more', More)
  Vue.component('a-icon', Icon)
  Vue.component('a-logo', Logo)
  Vue.component('a-tabs', Tabs)
  Vue.component('a-modal', Modal)
  Vue.component('a-badge', Badge)
  Vue.component('a-input', Input)
  Vue.component('a-button', Button)
  Vue.component('a-switch', Switch)
  Vue.component('a-timing', Timing)
  Vue.component('a-counter', Counter)
  Vue.component('a-hit-test', HitTest)
  Vue.component('a-checkbox', Checkbox)
  Vue.component('a-file-upload', FileUpload)
  Vue.component('a-color', Color)
}
