<template>
  <div id=page-config class=g3>
    <h2 class=c3>Dados de acesso</h2>
    <a-input placeholder=Nome  float v-model=config.name />
    <a-input placeholder=Email float v-model=config.user />
    <a-input placeholder=Senha float v-model=config.password type=password />

    <div class='g2 c3'>
      <a-input placeholder='Host IMAP'  float v-model=config.imap.host />
      <a-input placeholder='Porta IMAP' float v-model=config.imap.port type=number />
      <a-input placeholder='Host SMTP'  float v-model=config.smtp.host />
      <a-input placeholder='Porta SMTP' float v-model=config.smtp.port type=number />
    </div>

    <a-input class=c3 placeholder='Email do supervisor' float v-model=config.supervisor />


    <h2 class=c3>Conteúdo dos emails</h2>

    <a-tabs class=c3 headers='Folha de Pagamento,Décimo Terceiro,Não recebido' v-slot='{tab}'>
      <c-compose :mode=tab />
    </a-tabs>


    <h2 class=c3>Exportar e importar dados</h2>
    <p class=c3 style='margin-top:-20px'>
      <b>Cuidado!</b><br/>
      Ao importar um arquivo de configuração, você vai perder quaisquer alterações salvas.
    </p>
    <a-button icon=upload title='Importar' @click=upload>Importar</a-button>
    <span />
    <a-button icon=download title='Baixar' @click=download>Exportar</a-button>
  </div>
</template>
<script>
import {config} from '@/store'
import {downloadFile} from 'i/utils'
import {openFile,readFile} from 'i/utils'
export default {
  setup() {
    const keys = [
      'config','funcionarios','emailContent', // @/store.js
      'box-h','box-w','box-x','box-y', // @/ui/components/preview.vue
    ]
    const download = () => {
      const data = {}
      for (const key of keys) data[key] = localStorage.getItem(key)
      const blob = new Blob([JSON.stringify(data)], {type: 'application/json'})
      downloadFile(blob, 'dados.json')
    }
    const upload = () => {
      openFile(false, '.json')
      .then(file => readFile(file, true))
      .then(text => JSON.parse(text))
      .then(conf => {
        for (const key of keys) {
          if (conf[key]) localStorage.setItem(key, conf[key])
        }
        window.location.reload()
      })
    }
    return {
      config,
      download, upload,
    }
  }
}
</script>
<style lang="stylus">
#page-config {
  padding-top 12px
  flex 0 0 auto
  &, & > div {
    grid-row-gap 16px
    grid-column-gap 12px
  }
  h2:first-child {
    margin-top 0
  }
  padding-bottom 60px
}
</style>
