<template>
  <a-tabs id=site-contents v-if=tabs>
    <template #headers>
      <router-link v-for='(p,i) of pages' :to='{name:p.name}' :key=i class=tab active-class=active>
        <span>{{p.label}}</span>
      </router-link>
    </template>

    <router-view />
  </a-tabs>
  <div id=site-contents v-else>
    <router-view />
  </div>
</template>
<script>
import {routes} from '@/router'
export default {
  props: {
    tabs: Boolean,
  },
  setup() {
    return {
      pages: routes,
    }
  }
}
</script>
<style lang="stylus">
#site-contents {
  padding 0px 37px
  padding-bottom 160px
  min-height 100vh
  +mobile() {
    padding 0px 12px
  }
  &.a-tabs {
    padding-top 60px
  }
  &.a-tabs {
    padding-bottom 40px
    & > [id^="page-"] {
      overflow hidden
    }
  }
  &.a-tabs {
    display flex
    height 100vh
    flex-direction column
  }
}
</style>
