<template>
  <a-button v-if=button @click=upload><slot /></a-button>
  <div v-else class=a-file-upload @click=upload @drop.prevent=drop @dragover.prevent @dragenter.prevent='drag = true' @dragleave='drag = false' :class='{drag,error:!!error}'>
    <span v-if=error>{{error}}</span>
    <slot v-else>
      Arraste um arquivo aqui,<br/>
      ou clique para selecionar
    </slot>
  </div>
</template>
<script>
import {ref} from 'vue'
import {openFile,readFile} from 'i/utils'
export default {
  emits: ['upload'],
  props: {
    allowed: {type: [Array,String], default: () => []},
    button: Boolean,
    decode: Boolean,
    string: Boolean,
  },
  setup(props, {emit}) {
    const error = ref('')
    const drag = ref(false)
    const read = async file => {
      error.value = ''
      if (props.allowed?.length) {
        const allowed = (typeof props.allowed === 'string'
          ? props.allowed.split(',')
          : props.allowed
        )
        const extension = file.name.split('.').pop().toLowerCase()
        if (!allowed.includes(extension)) {
          error.value = 'Tipo de arquivo não aceito.\nEnvie um ' + (allowed.length === 1
            ? allowed[0]
            : allowed.slice(0, -1).join(', ') +  ' ou ' + allowed[allowed.length - 1]
          )
          return;
        }
      }
      if (!props.decode) return emit('upload', file)
      const data = await readFile(file, props.string)
      emit('upload', data)
    }
    const drop = e => {
      drag.value = false
      const file = Array.from(e.dataTransfer.items || []).find(x => x.kind === 'file')?.getAsFile()
      if (file) read(file)
    }
    const upload = async () => {
      const file = await openFile()
      if (file) read(file)
    }

    return {
      drag, drop,
      error, upload,
    }
  }
}
</script>
<style lang="stylus">
.a-file-upload {
  height 300px
  width @height
  cursor pointer
  border 2px dashed $base
  border-radius 18px
  display flex
  align-items center
  flex-direction column
  justify-content center
  background-color $background
  &.drag {
    background-color alpha($base, 20%)
  }

  text-align center
  margin 0
  color $base
  font-size 18px
  white-space pre-line
  &.error {
    background-color lighten($bg-danger, 90%)
    border-color $fg-danger

    color $fg-danger
    font-weight bold
  }
}
</style>
