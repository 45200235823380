<template>
  <div class=c-preview>
    <div class=box ref=box :class='{hide:!url}' />
    <!-- isso aqui é só pra esconder uma linha horrorosa do PDF -->
    <div class=hide-line />
    <canvas ref=canvas />
  </div>
</template>
<script>
import {onMounted,ref,watch} from 'vue'
import {persistentRef} from 'i/compose'
import {found,pdfUrl} from '@/store'

import interact from 'interactjs'
import {createWorker} from 'tesseract.js'

const PDFJs = require('pdfjs-dist')
import {jsPDF} from 'jspdf'

export default {
  props: {
    page: {type: Number, default: 1},
  },
  setup(props, {expose}) {
    /* render pdf */
    const canvas = ref(null)

    let doc = null, pages = 0
    const renderDoc = async (justUpload) => {
      if (!pdfUrl.value) return;
      doc = await PDFJs.getDocument(pdfUrl.value).promise
      pages = doc.numPages
      if (pages === 0) return;
      if (justUpload===true && (x.value > 0 && y.value > 0)) {// se já definiu a caixa
        await process()
      }
      else {// senão, define antes de processar
        await renderPage(props.page)
      }
    }
    let rendering = false
    const renderPage = async Num => {
      if (rendering) return;
      rendering = true
      const page = await doc.getPage(Num)
      // Display page on the existing canvas with 100% scale.
      const viewport = page.getViewport({scale: 1.5})
      canvas.value.height = viewport.height
      canvas.value.width = viewport.width
      const ctx = canvas.value.getContext('2d')
      await page.render({canvasContext: ctx, viewport}).promise
      rendering = false
    }

    onMounted(() => renderDoc())
    watch(pdfUrl, () => renderDoc(true))
    watch(() => props.page, renderPage)

    /* render box */
    const box = ref(null)
    const x = persistentRef('box-x',  64, parseFloat)
    const y = persistentRef('box-y',  102, parseFloat)
    const w = persistentRef('box-w', '58px')
    const h = persistentRef('box-h', '20px')
    onMounted(() => {
      box.value.style.width = w.value
      box.value.style.height = h.value
      box.value.style.transform = 'translate('+x.value+'px, '+y.value+'px)'

      // https://interactjs.io/
      interact(box.value)
      .draggable({
        listeners: {
          move: event => {
            var target = event.target
            x.value += event.dx
            y.value += event.dy
            // translate the element
            target.style.transform = 'translate(' + x.value + 'px, ' + y.value + 'px)'
          },
        },
        inertia: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ]
      })
      .resizable({
        // resize from all edges and corners
        edges: { left: false, right: true, bottom: true, top: false },
        listeners: {
          move(event) {
            var target = event.target

            // update the element's style
            w.value = target.style.width = event.rect.width + 'px'
            h.value = target.style.height = event.rect.height + 'px'

            // translate when resizing from top or left edges
            x.value += event.deltaRect.left
            y.value += event.deltaRect.top

            target.style.transform = 'translate(' + x.value + 'px,' + y.value + 'px)'
          }
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: 'parent'
          }),

          // minimum size
          interact.modifiers.restrictSize({
            min: { width: 50, height: 20 }
          })
        ],
        inertia: true,
      })
    })

    /* OCR */
    let cvs, blobUrl, worker
    const setup = async () => {
      cvs = document.createElement('canvas')
      cvs.setAttribute('class', 'view-canvas')
      // document.body.appendChild(cvs)
      worker = await createWorker()
      await worker.loadLanguage('eng')
      await worker.initialize('eng')
    }
    const ORC = async () => {
      if (!worker) await setup()
      const _w = parseFloat(w.value)
      const _h = parseFloat(h.value)
      cvs.width  = _w
      cvs.height = _h
      const ctx = cvs.getContext('2d')
      ctx.drawImage(canvas.value, x.value, y.value, _w, _h, 0, 0, _w, _h)
      const blob = await new Promise((res, rej) => cvs.toBlob(b => b ? res(b) : rej() , 'image/png', 1))
      if (blobUrl) URL.revokeObjectURL(blobUrl)
      blobUrl = URL.createObjectURL(blob)
      const {data} = await worker.recognize(blobUrl)
      return data.text?.trim()
    }
    onMounted(setup)

    /* expose interface */
    const process = async () => {
      found.length = 0
      for (let i = 0; i < pages; ++i) {
        await renderPage(i+1)
        const code = await ORC()
        found.push(code)
      }
      await renderPage(props.page)
      return found
    }
    const getPage = () => {
      const data = canvas.value.toDataURL('image/jpeg', 1)
      const [w,h] = [canvas.value.width, canvas.value.height]
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [w,h],
      })
      pdf.addImage(data, 'JPEG', 0, 0, w, h)
      // doc: https://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html#output
      return pdf.output('datauristring', 'documento.pdf')
    }

    expose({process, renderPage, getPage})

    return {
      url: pdfUrl,
      canvas,
      box,
    }
  }
}
</script>
<style lang="stylus">
.c-preview {
  width 100%
  height 100%
  overflow hidden
  position relative

  .box {
    border 1px solid red
    position absolute
    top 0px
    left 0px
    width 50px
    height 20px
    background rgba(255,0,0,0.4)
    touch-action none
    &.hide {
      opacity 0
      pointer-events none
    }
  }
  .hide-line {
    position absolute
    top 9px
    left 0px
    width 100%
    height 6px
    pointer-events none
    background-color $background
  }
}
.view-canvas {
  position fixed
  top 100px
  left 100px
  border 1px solid blue
}
</style>
