<template>
  <div id=page-logs>
    <div class=data-view>
      <a-input placeholder=Pesquisar icon=search v-model=query v-if='!initial' />

      <!-- loading de prepend -->
      <a-icon name=spinner v-if='loading===2' />

      <template v-for='month of list' :key='month[0]'>
        <div class=month-heading>
          <a-icon v-if='downloading[month[0]]' name=spinner />
          <span>{{month[0]}}</span>
          <a-more>
            <template #handler><a-icon name=triangle /></template>
            <ul>
              <li @click='download(0,month)'>Baixar como CSV</li>
              <li @click='download(1,month)'><a-icon v-if='downloading[month[0]]' name=spinner /> Baixar as respostas</li>
            </ul>
          </a-more>
        </div>
        <div class=item v-for='email of month[1]' :key=email.id @click='open=email' :class='{active:open?.uid===email.uid}'>
          <span class=code>{{email.funcionario.code}}</span>
          <span class=code>{{email.funcionario.place}}</span>
          <span class=name>{{email.funcionario.name}}</span>

          <a-badge class=good v-if='email.answerType===2'>Respondido</a-badge>
          <a-badge class=warn v-else-if='email.answerType===1'>Respondido sem anexo</a-badge>
          <a-badge class=bad  v-else>Não Respondido</a-badge>
        </div>
      </template>
      <!-- loading de append -->
      <a-icon name=spinner v-if='loading===1' />

      <p class=error v-if=error>{{error}}</p>
    </div>
    <c-mail-reader :header=open v-if='!initial' />
    <a-hit-test @hit=loadMore v-if='!loading && !finished' />
  </div>
</template>
<script>
import {funcionarios} from '@/store'
import {logs,cDate,lDate,lquery} from '@/store'
import {computed,onMounted,ref,watch} from 'vue'
import {Funcionario,sleep,regexFromString} from 'i/utils'
import {getAddress} from 'i/utils'
import {downloadFile} from 'i/utils'
import {downloadAttchBlob} from 'i/utils'
import {reactive} from 'vue'
import JSZip from 'jszip'
import Load from '@/store/logs'
export default {
  setup() {
    const emails = logs
    const open = ref(null)
    const error = ref(null)
    const loading = ref(0)
    const finished = ref(false)
    const idMap = Object.create(null) // {id -> email}
    const toMap = Object.create(null) // {to -> email}
    const orphans = []
    const initial = ref(true)
    const query = lquery
    const queryString = ref('')
    const downloading = reactive({})

    const load = async (loadType=1) => {
      if (loading.value) return;
      loading.value = loadType
      error.value = null

      await sleep(100) // só pra ter certeza de que o search é chamado depois do config
      try {
        await Load({emails, orphans, idMap, toMap})
        initial.value = false
      }
      catch (e) { return error.value = e }

      loading.value = 0
    }
    const loadMore = () => {
      if (loading.value) return;
      if (!cDate.value) cDate.value = new Date()
      // limite temporal:
      else if (cDate.value.getFullYear() <= 2023 && cDate.value.getMonth() <= 7) return finished.value = true
      else cDate.value.setMonth(cDate.value.getMonth() - 1)

      load()
    }
    const reload = date => {
      emails.length = 0
      cDate.value = date
      finished.value = false
      for (const key in idMap) delete idMap[key]
      load()
    }

    const list = computed(() => {
      const dateList = []
      const dateMap = Object.create(null)
      const q = queryString.value.trim()
      const rgx = q && regexFromString(q)
      for (const item of emails) {
        const date = item.competencia //item.date.toLocaleDateString()
        let arr = dateMap[date]
        if (!arr) {
          dateList.push(arr = dateMap[date] = [date, []])
          arr.order = item.compet[1] + item.compet[0]/13
          arr.compet = item.compet
        }
        const to = getAddress(item.to)
        let func = funcionarios.find(y => y.email === to)
        if (func) {
          if (rgx && !(
            func.code.includes(q) || rgx.test(func.name)
            || rgx.test(func.place) || rgx.test(item.to)
            || rgx.test(item.suject)
          )) continue // filtra esse email
        }
        else {
          if (rgx && !rgx.test(item.to)) continue // esse não passa no teste
          func = Funcionario({
            email: getAddress(item.to),
            name: item.to.includes('<') ? item.to.split('<')[0].trim() : ''
          })
        }
        arr[1].push({...item, funcionario: func})
      }
      for (const arr of dateList) {
        arr[1].sort((a,b) => a.funcionario.name.localeCompare(b.funcionario.name))
      }
      return dateList.filter(x => x[1].length > 0).sort((a,b) => b.order - a.order)
    })

    onMounted(() => {
      if (emails.length) initial.value = false

      for (const item of emails) {
        idMap[item.id] = item
      }
    })


    // agora a parte da pesquisa
    watch(query, () => {
      const date = query.value.match(/(\d{1,2}\/)?\d{1,2}\/\d{2}/)?.[0]
      if (date) {
        if (date !== lDate.value) {
          lDate.value = date

          const [mm,yy] = date.split('/').slice(-2).map(x => parseInt(x))
          const d = new Date(); d.setMonth(mm - 1); d.setFullYear(2000 + yy)
          reload(d)
        }
        queryString.value = query.value.replace(date, '')
      }
      else {
        if (date !== lDate.value) {
          lDate.value = date
          reload(new Date())
        }
        queryString.value = query.value
      }
    }, {immediate: true})

    const download_csv = month => {
      const csv = 'local;email;nome;resposta\n' + month[1].map(email => {
        const f     = email.funcionario
        const resp  = (email.answerType === 2
          ? 'Enviou'
          : (email.answerType === 1
            ? 'Enviou sem anexo'
            : 'Não enviou'
          )
        )
        return `${f.place};${f.email};${f.name};${resp}\n`
      }).join('')

      const q = query.value.trim()
      const name = (month.compet
        ? month.compet[1] + '-' + ('0'+month.compet[0]).slice(-2)
        : month[0]
      ) + (q ? (' - '+q) : '')
      downloadFile(
        new Blob([csv], {type: 'text/csv'}),
        'Relação '+name+'.csv'
      )
    }
    const download_attch = month => {
      const q = query.value.trim()
      const name = (month.compet
        ? month.compet[1] + '-' + ('0'+month.compet[0]).slice(-2)
        : month[0]
      ) + (q ? (' - '+q) : '')

      downloading[month[0]] = true
      Promise.all(
        month[1].map(email => {
          if (email.answerType !== 2) return null
          const child = email.children[email.children.length - 1]
          return downloadAttchBlob(1, {...child, funcionario: email.funcionario})
        }).filter(x => x)
      )
      .then(blobs => {
        // muitos blobs.
        const zip = new JSZip()
        for (const blob of blobs) {
          zip.file(blob.name, blob)
        }
        return zip.generateAsync({type: 'blob'})
      })
      .then(blob => downloadFile(blob, 'Respostas '+name+'.zip'))
      .catch(e => alert(e))
      .finally(() => downloading[month[0]] = false)
    }
    const download = (type,month) => {
      if (type === 0) return download_csv(month)
      else download_attch(month)
    }

    return {
      list, open, query, initial,
      loading, finished, error, loadMore,
      download, downloading,
    }
  }
}
</script>
<style lang="stylus">
#page-logs {
  display flex
  overflow hidden
  .c-mail-reader {
    flex 0 0 40%
    min-width 400px
  }

  .data-view {
    flex 1 1 50%
    overflow auto

    & > .a-input {
      margin-bottom 40px
    }
    & > .a-icon {
      display block
      margin-left auto
      margin-right auto
      margin-top 40px
    }
    & > .error {
      color $fg-danger
    }
  }

  .month-heading {
    margin-bottom 20px
    background-color $base
    display inline-flex
    align-items center
    position sticky
    width 100%
    padding 0px 10px
    top 0px
    & > span {
      font-size 1.2rem
      color $contrast
      flex auto 1 1
    }
    & > .icon-spinner {
      fill $contrast
    }
    .icon-spinner {
      margin-right 12px
    }
    & > .a-more {
      flex auto 0 0
      margin-left auto
      .icon-triangle {
        stroke $contrast
        fill $base
      }
      &.active .icon-triangle {
        stroke $base
      }
      ul {
        margin 0
        white-space nowrap
        padding 0px
        list-style none
        li {
          padding 12px 10px
          text-align right
          cursor pointer
          &:hover {
            background-color $base
            color $contrast
            .icon-spinner {
              fill $contrast
            }
          }
        }
      }
    }
  }
  .data-view .item {
    display flex
    align-items center
    padding 8px 6px
    margin-bottom 12px
    border-bottom 1px solid $bg-gray
    user-select none
    cursor pointer
    & + .a-badge {
      margin-top 40px
    }
    &:hover,&.active {
      background-color alpha(#7584CF, 0.2)
    }
    .code {
      flex 0 0 60px
      min-width 60px
      display inline-block
    }
    .name {
      flex 1 1 auto
      padding 0px 16px
      display inline-block
    }
    .a-badge {
      flex 0 0 auto
      display inline-block
      &.good {
        background-color green
      }
      &.bad {
        background-color $bg-danger
      }
      &.warn {
        background-color $bg-warn
      }
    }
  }
}
</style>
