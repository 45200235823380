import {persistent} from 'i/compose'
import {ref,reactive} from 'vue'
import {watch} from 'vue'

export const config = persistent(reactive, 'config', {
  name: 'ETI Tecnologia',
  supervisor: '',
  user: 'eti_contracheques@etitecnologia.com.br',
  password: 'ETI274ccheque!',
  imap: {host: 'imap.hostinger.com', port: 993},
  smtp: {host: 'smtp.hostinger.com', port: 465},
  tls: true,
}, JSON.parse, JSON.stringify)
export const funcionarios = persistent(reactive, 'funcionarios', [], JSON.parse, JSON.stringify)

export const found  = reactive([])
export const pdfUrl = ref(null)
export const viewData = ref(null)

// pra logs
export {logs,cDate,lDate,lquery,resetLogs} from './logs.js'
//export const logs = reactive([])
//export const cDate = ref(null) // current date for logs
//export const lDate = ref(null) // last date for querying
//export const lquery = ref('')
//export const resetLogs = () =>{
//  logs.length = 0
//  cDate.value = null
//  lDate.value = null
//  lquery.value = ''
//}

// conteúdo dos emails
export const emailContent = persistent(reactive, 'emailContent', [
  {subject: '', ops: {}}, // folha de pagamento
  {subject: '', ops: {}}, // décimo terceiro
  {subject: '', ops: {}}, // atraso
], JSON.parse, JSON.stringify)


// configura o email
const update = () =>
  window.$main.config(JSON.parse(JSON.stringify(config)))
let t
watch(config, () => {
  clearTimeout(t)
  t = setTimeout(update, 500)
})
update()
