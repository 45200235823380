import {funcionarios} from '@/store'

const REGEX_SQLITE_COMPATIBLE = false
export function regexFromString(string) {
  if (REGEX_SQLITE_COMPATIBLE) {
    // O sqlite não consegue lidar com utf8 corretamente.
    // Daí precisamos duplicar os caracteres acentuados pras
    // suas versões maiúsculas e minísculas, e temos de usar
    // grupos ao invés de [].
    string = string.toLowerCase().replace(/([[\]()|$%^*+&])/g, '\\$1')
    .replace(/[aáàâãā]/g, '(a|á|à|â|ã|Á|À|Â|Ã)')
    .replace(/[eéê]/g   , '(e|é|ê|É|Ê)')
    .replace(/[ií]/g    , '(i|í|Í)')
    .replace(/[oôóõ]/g  , '(o|ô|ó|õ|Ô|Ó|Õ)')
    .replace(/[uüú]/g   , '(u|ü|ú|Ü|Ú|)')
    .replace(/[cç]/g    , '(c|ç|Ç)')
    .replace(/[\s\-._,!?:]+/g, '[\\s\\-._,!?:]*')
  }
  else {
    string = string.toLowerCase().replace(/([[\]()|$%^*+&])/g, '\\$1')
    .replace(/[aáàâãā]/g , '[aáàâãā]')
    .replace(/[eéê]/g    , '[eéê]')
    .replace(/[ií]/g     , '[ií]')
    .replace(/[oôóõ]/g   , '[oôóõ]')
    .replace(/[uüú]/g    , '[uüú]')
    .replace(/[cç]/g     , '[cç]')
    .replace(/[\s\-._,!?:]+/g, '[\\s\\-._,!?:]*')
  }
  return new RegExp(string, 'i')
}
export function openFile(multiple, accept) {
  return new Promise(resolve => {
    const i = document.createElement('input')
    i.setAttribute('type', 'file')
    i.setAttribute('style', 'position:fixed; top: -100px;')
    if (multiple) i.setAttribute('multiple', 'true')
    if (accept) i.setAttribute('accept', accept)
    i.addEventListener('change', event => {
      resolve(multiple ? Array.from(event.target.files) : event.target.files[0])
    })
    document.body.append(i)
    i.click()
    setTimeout(() => i.remove(), 300)
  })
}
export function readFile(file/*File instance*/, asText) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onload = data => resolve(data.currentTarget.result)
    fr.onerror = error => reject(error)
    if (asText) fr.readAsText(file)
    else fr.readAsArrayBuffer(file)
  })
}
export function downloadFile(blob, name) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', name)
  a.click()
  setTimeout(() => URL.revokeObjectURL(url), 5000)
}

export function momento(value) {
  if (!(value instanceof Date)) value = new Date(value)
  const now = new Date()
  const thisYear = now.getFullYear()
  const diff = (now - value) / 1000
  if (diff < 60) return 'há menos de um minuto'
  if (diff < 3600) {
    if (diff > 119) return `há ${Math.floor(diff/60)} minutos`
    return `há um minuto`
  }
  if (diff < 12 * 3600) {
    const horas = Math.floor(diff / 3600)
    if (horas === 1) return `há ${horas} hora`
    return `há ${horas} horas`
  }
  //if (diff < 18000) return `Há ${Math.floor(diff/3600)} horas`
  const hora = `${('0'+value.getHours()).slice(-2)}:${('0'+value.getMinutes()).slice(-2)}`
  if (sameDay(now, value)) return `às ${hora}`
  now.setDate(now.getDate() - 1)
  if (sameDay(now, value)) return `ontem às ${hora}`
  let dia = `${('0'+value.getDate()).slice(-2)}/${('0'+(value.getMonth()+1)).slice(-2)}`
  if (thisYear !== value.getFullYear()) dia += '/'+value.getFullYear()
  return `${dia} às ${hora}`
}
function sameDay(now, then) {
  return (
    now.getFullYear() === then.getFullYear() &&
    now.getMonth() === then.getMonth() &&
    now.getDate() === then.getDate()
  )
}

export function sleep(ms) {
  return new Promise(r => setTimeout(r, ms))
}

function f_clear() {
  this.place  = ''
  this.email  = ''
  this.code   = ''
  this.name   = ''
  this.active = true
}
export function Funcionario(def={}) {
  if (def.code) def.code = def.code.padStart(6, '0')
  const fun = {
    place: '',
    email: '',
    code: '',
    name: '',
    active: true,
    ...def,
  }
  Object.defineProperty(fun, 'clear', {value:f_clear})
  return fun
}

const months_r = [
  /\bjane?i?r?o?\b/,
  /\bfeve?r?e?i?r?o?\b/,
  /\bmarç?o?\b/,
  /\babri?l?\b/,
  /\bmaio?\b/,
  /\bjunh?o?\b/,
  /\bjulh?o?\b/,
  /\bagos?t?o?\b/,
  /\bsete?m?b?r?o?\b/,
  /\boutu?b?r?o?\b/,
  /\bnove?m?b?r?o?\b/,
  /\bdeze?m?b?r?o?\b/,
  /\bd[eé]cimo\s+?t?e?r?c?e?i?r?o?\b/
]
const months = [
  'Janeiro','Fevereiro','Março','Abril',
  'Maio','Junho','Julho','Agosto','Setembro',
  'Outubro','Novembro','Dezembro',
  'Décimo Terceiro',
]
export function extractCompetencia(human_text) {
  human_text = human_text.toLowerCase().replace('_', ' ') // tem gente que usa _ como separador
  // casos comuns: janeiro( de |-|/)(20)24, 01[-/ ]2024, 01[-/ ](20)24
  let year = human_text.match(/(20)?[23456789]\d/)?.[0] // 2020 até 2099. Para de funcionar no próximo século
  if (year) {
    year = parseInt(year)
    if (year < 2000) year += 2000
  }
  else {
    const d = new Date()
    const m = d.getMonth()
    // se estamos em janeiro, a competência é do ano passado.
    // Senão, é dessse ano
    if (m === 0) year = d.getFullYear() - 1
    else year = d.getFullYear()
  }
  human_text = human_text.replaceAll(year, '')
  let month = human_text.match(/\b((0[1-9])|(1[123]))\b/)?.[0] // 01 até 13 (tem o 13º salário)
  if (month) {
    human_text = human_text.replace(month, '')
    month = parseInt(month) - 1
  }
  else {
    for (let i = 0; i < 13; ++i) {
      if (months_r[i].test(human_text)) {
        month = i
        human_text = human_text.replace(months_r[i], '')
        break
      }
    }
  }
  let parcela = 0
  if (month === 12) {
    if (human_text.includes('primeir')) parcela = 1
    else if (human_text.includes('segund')) parcela = 2
    else if (human_text.includes('1')) parcela = 1
    else if (human_text.includes('2')) parcela = 2
  }

  return [month + 1, year, parcela]
}
export function competenciaToString(competencia) {
  if (Array.isArray(competencia)) {
    return months[competencia[2] ? 12 : competencia[0] - 1]
     + ' '
     + competencia[1]
     + (competencia[2] ? ` (parte ${+competencia[2]})` : '')
  }
  return competencia
}

export function getAddress(str) { // name@name.com -> name@name.com; hey <name@name.com> -> name@name.com
  return str.split('<').pop().split('>')[0]
}



export function downloadAttchBlob(box, email, part) {
  box = box === 0 ? 'INBOX.Sent' : 'INBOX'

  if (!part) {
    part = (
      email.struct.attachments?.find(x => x.subtype === 'pdf') ||
      email.struct.attachments?.[0]
    )
  }

  let name = part.params.name
  if (email.compet) {
    const [mm,yyyy] = email.compet
    const ext = name.split('.').pop()
    const addr = getAddress(email.from)
    const funcionario = email.funcionario || funcionarios.find(x => x.email === addr)
    if (funcionario) {
      name = `${funcionario.place}-${yyyy}-${('0'+mm).slice(-2)} ${funcionario.name}.${ext}`
    }
  }
  return window.$main.details(box, {uid: email.uid}, {partID: part.partID, encoding: part.encoding})
  .then(resp => {
    const blob = new Blob([resp], {type: part.type+'/'+part.subtype})
    blob.name = name
    return blob
  })
}

export function partition(arr, fn) {
  return arr.reduce((acc, value, i) => {
    acc[fn(value, i) ? 0 : 1].push(value)
    return acc
  }, [[], []])
}
