<template>
  <div class=a-checkbox :class={active:modelValue} @click.stop="$emit('update:modelValue', !modelValue)">
  </div>
</template>
<script>
export default {
  props: {
    modelValue: Boolean,
  },
}
</script>
<style lang="stylus">
.a-checkbox {
  width $w = 24px
  height @width
  border-radius 6px
  border 2px solid $bg-gray
  cursor pointer
  transition border-color $time ease, background-color $time ease
  display inline-block
  vertical-align middle
  &.active {
    background-color $base
    border-color $base
    position relative
    &::before,&::after {
      content ' '
      position absolute
      border 2px solid $contrast
      border-radius 4px
    }
    &::before {
      top 40%
      left 20%
      width 30%
      transform rotate(50deg)
      transform-origin top left
    }
    &::after {
      top 10%
      right 20%
      width 70%
      transform rotate(-50deg)
      transform-origin top right
    }
  }
}
</style>
