<template>
  <div class=a-badge>
    <slot />
  </div>
</template>
<style lang="stylus">
.a-badge {
  height 36px
  color $contrast
  border-radius 18px
  line-height @height
  display inline-flex
  vertical-align middle
  background-color $bg-gray
  text-align center
  user-select none

  width auto
  font-size 17px
  min-width 119px
  padding 0px 12px
  align-items center
  justify-content center
}
</style>
